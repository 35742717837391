const ready = () => {
    document.getElementById('banner-close')?.addEventListener('click', () => {
            let banner = document.getElementById('banner-new-carbonus');
            banner.style.display = 'none'
    });
}

document.addEventListener('DOMContentLoaded',ready);

window.onload = (ev) => {

    let frame = $('#banner-new-carbonus-frame');
    let frameContent =  $('#banner-new-carbonus-content');
    //console.log('onload frame height', frame.contents().height())
    let frameHeight = frame.contents().height();
    //let banner = document.getElementById('banner-new-carbonus');
    let banner = $('#banner-new-carbonus');
    if(frameHeight > 1){
        // установка высоты баннера
        frame.height(frameHeight);
    }else{
        // скрыть и не показывать
        banner.css('display','none');
    }

    window.onresize = (ev) => {

        let bannerFrame = $('#banner-new-carbonus-frame');
        let banner = $('#banner-new-carbonus');
        let bannerContentHeight = bannerFrame.contents().height();
        let bannerContentHeight2 = bannerFrame.contents().find('body').outerHeight(true);

        let bannerHeight = banner.height();

        if(bannerContentHeight2 !== bannerHeight){
            bannerFrame.height(bannerContentHeight2);
        }
    }
}